import util from './util';
import constant from './constant';
// import buildings from './buildings.json';

const { BUILDING_TYPE, BUILDING_ICON } = constant;
const { createDiv } = util;

export default class Building {
    constructor(options) {
        const defaultOptions = {
            type: BUILDING_TYPE.BUILDING,
            displayOption: null,
            displayOrder: null,
            organizationCount: 0,
            organizations: [],
            has3D: false,
            matterportUrl: null,
        };

        this.options = Object.assign({}, defaultOptions, options);

        return {
            ...this.options,
            parseMarkerElement: this.parseMarkerElement,
            getAll: this.constructor.getAll,
            get: this.constructor.get,
        };
    }

    parseMarkerElement(callback) {
        const building = this;
        const markerDiv = createDiv();
        markerDiv.classList.add('marker');
        if (building.type && building.type !== BUILDING_TYPE.BUILDING) {
            markerDiv.classList.add(building.type);
        }
        markerDiv.setAttribute('data-name', building.name);
        if (building.opOrg) {
            markerDiv.setAttribute('data-org', building.opOrg);
        }
        markerDiv.setAttribute('data-addr', building.address_base);
        markerDiv.style.top = building.position.x + 'px';
        markerDiv.style.left = building.position.y + 'px';
        if (building.has3D) {
            markerDiv.classList.add('mp');
        }

        const markerImageDiv = createDiv();
        markerImageDiv.classList.add('marker-image');
        if (building.type && BUILDING_ICON[building.type]) {
            markerImageDiv.style.backgroundImage = `url(${BUILDING_ICON[building.type]})`;
        }
        markerDiv.appendChild(markerImageDiv);

        const markerTextDiv = createDiv();
        markerTextDiv.classList.add('marker-text');

        let name = building.name;
        markerTextDiv.textContent = name;
        if (building.organizationCount > 0) {
            const markerCountSpan = document.createElement('span');
            markerCountSpan.classList.add('count');
            markerCountSpan.textContent = '+' + building.organizationCount;
            markerTextDiv.appendChild(markerCountSpan);
            markerDiv.classList.add('multiple');
        }
        markerDiv.appendChild(markerTextDiv);

        if (building.has3D && building.matterportUrl !== null) {
            // add 3D view button
            const marker3DViewDiv = createDiv();
            marker3DViewDiv.classList.add('marker-3dview');
            marker3DViewDiv.addEventListener('click', function (e) {
                e.stopPropagation();
                window.dispatchEvent(new CustomEvent('showcase-open', {
                    detail: {
                        src: building.matterportUrl,
                    },
                }));
                return false;
            });
            markerDiv.appendChild(marker3DViewDiv);
        }

        if (building.displayOption === 'flip') {
            markerDiv.classList.add('flip');
        }

        if (building.displayOrder !== null) {
            markerDiv.style.zIndex = building.displayOrder;
        }

        const subOrgDiv = createDiv();
        subOrgDiv.classList.add('sub-org-list');
        subOrgDiv.classList.add('hidden');
        markerDiv.appendChild(subOrgDiv);

        markerDiv.addEventListener('click', async function (e) {
            e.stopPropagation();
            if (callback !== undefined && callback instanceof Function) {
                await callback.apply(this, [e]);
            }
            return false;
        });

        return markerDiv;
    }
}
