export default {
    createDiv: function () {
        return document.createElement('div');
    },
    createSpan: function () {
        return document.createElement('span');
    },
    createBr: function () {
        return document.createElement('br');
    },
    clearAllChildNodes: function (target) {
        // clear all child nodes
        while (target.children.length > 0) {
            target.removeChild(target.children[target.children.length - 1]);
        }
    },
    changeImage: function (img, src) {
        if (img) {
            img.src = src;
        }
    }
};
