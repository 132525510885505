import axios from 'axios';
import OpOrganization from './opOrganization';
import Organization from './organization';
import * as Marker from './marker';
import util from './util';
import constant from './constant';
import Building from './building';
import * as events from './events';

const { initEvent } = events;
const { opOrgs, BUILDING_TYPE, ORG_ACTION, OPORG_ALL, markerOffsetHeight } = constant;
const { clearAllChildNodes } = util;
const { onClickMarker } = Marker;

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Fassker = (function () {
    'use strict';

    const exports = {};
    exports.init = async function (options) {
        const { organizations, buildings, notice } = options;

        function getOrganizationByAddress(address) {
            return organizations.filter(function (org) {
                return org.address_base === address;
            });
        }

        function getOrganizationsByOpOrg(opOrg) {
            return organizations.filter(function (org) {
                return org.op_org === opOrg;
            });
        }

        function getBuildingByName(name) {
            return buildings.filter(function (building) {
                return building.name === name;
            }).pop();
        }


        function createMarkerWithBuilding(target, building, callback) {
            let marker = document.querySelector(`.marker[data-name="${building.name}"]`);

            if (!marker) {
                marker = building.parseMarkerElement(callback);
                // change max-width of specific markers
                switch (marker.dataset.name) {
                    // case '킨스타워':
                    //     marker.style.maxWidth = '158px';
                    //     break;
                    case 'LH기업성장센터':
                        marker.style.maxWidth = '191px';
                        break;
                    case '성남창업센터 정글ON (판교2밸리 창업지원주택)':
                        if (marker.classList.contains('flip')) {
                            marker.style.left = null;
                            marker.style.right = 'calc(100% - 340px)';
                        }
                        break;
                    // case 'LH기업성장센터':
                    //     marker.classList.add('flip');
                    //     break;
                }
                target.appendChild(marker);
            }

            return marker;
        }

        function showAllSubOrg() {
            const subOrgItems = document.querySelectorAll('#mapLayer .marker .sub-org-list .item');
            for (let idx in subOrgItems) {
                const item = subOrgItems[Number(idx)];
                if (!item || !item.dataset.org) {
                    continue;
                }
                item.classList.remove('hidden');
                const markerCount = item.parentElement.parentElement.querySelector('.marker-text .count');
                if (markerCount) {
                    markerCount.textContent = `+${item.parentElement.querySelectorAll('.item:not(.hidden)').length}`;
                }
            }
        }

        function onClickOpOrgListItem(opOrg) {
            return function (e) {
                // close company list if opened
                window.dispatchEvent(new CustomEvent('company-list-close'));

                // unselect op-org
                const selected = document.querySelector('.org-list .selected');
                if (selected) {
                    selected.classList.remove('selected');
                }

                const target = e.target;
                if (target.tagName !== 'LI') {
                    target.parentNode.classList.add('selected');
                } else {
                    target.classList.add('selected');
                }
                window.dispatchEvent(new CustomEvent('menu-select', {
                    detail: {
                        opOrg,
                    }
                }));
            };
        }

        document.querySelector('.belt > .ict-belt')
            .addEventListener('click', function () {
                // toggle state
                if (!this.classList.contains('selected')) {
                    this.classList.add('selected');
                    document.querySelector('.ict-belt-layer').classList.remove('hidden');
                } else {
                    this.classList.remove('selected');
                    document.querySelector('.ict-belt-layer').classList.add('hidden');
                }
            });

        document.querySelector('.belt > .biohealth-belt')
            .addEventListener('click', function () {
                // toggle state
                if (!this.classList.contains('selected')) {
                    this.classList.add('selected');
                    document.querySelector('.biohealth-belt-layer').classList.remove('hidden');
                } else {
                    this.classList.remove('selected');
                    document.querySelector('.biohealth-belt-layer').classList.add('hidden');
                }
            });

        document.getElementById('showcase_close')
            .addEventListener('click', function (e) {
                e.stopPropagation();
                window.dispatchEvent(new CustomEvent('showcase-close'));
            });

        document.getElementById('showcase_close')
            .addEventListener('touchend', function (e) {
                e.stopPropagation();
                window.dispatchEvent(new CustomEvent('showcase-close'));
            });

        document.querySelector('.menu.left .previous')
            .addEventListener('click', function () {
                // close company list
                document.querySelector('.company-list').classList.add('hidden');
                // hide itself
                this.classList.add('hidden');
            });

        const container = document.querySelector('.container');
        const mapLayer = document.querySelector('#map > #mapLayer');
        const orgListDiv = container.querySelector('.menu.left .org-list');

        // clear organization list
        clearAllChildNodes(orgListDiv);

        // add all
        const allOrgs = organizations.filter(function (org) {
            return org.status !== ORG_ACTION.HIDDEN;
        });
        const totalCount = allOrgs.length;
        const allOpOrg = new OpOrganization({
            name: OPORG_ALL,
        });
        allOpOrg.organizations = allOrgs;
        allOpOrg.organizationCount = allOrgs.length;
        const el = allOpOrg.parseListItemElement(async function () {
            // const target = e.target;
            // close company list if opened
            window.dispatchEvent(new CustomEvent('company-list-close'));

            // hide panel
            const panelDiv = document.querySelector('.container > .panel');
            // clear panel child node
            clearAllChildNodes(panelDiv);

            // hide panel
            panelDiv.classList.add('hidden');
            window.dispatchEvent(new CustomEvent('panel-closed'));
            // await exports.displayOpOrganizationList();
            showAllSubOrg();

            // hide sub-org list in marker
            const subOrgList = document.querySelector('.sub-org-list:not(.hidden)');
            if (subOrgList) {
                subOrgList.classList.add('hidden');
            }

            // unselect op-org
            const selected = document.querySelector('.org-list .selected');
            if (selected) {
                selected.classList.remove('selected');
            }
            // select all op-org
            document.querySelector('.org-list li[data-org="전체"]').classList.add('selected');

            // reveal all markers
            const markers = document.querySelectorAll('#mapLayer .marker');
            for (let idx in markers) {
                const marker = markers[Number(idx)];
                if (marker) {
                    if (marker.classList.contains('hidden')) {
                        marker.classList.remove('hidden');
                    }
                    if (marker.classList.contains('selected')) {
                        marker.classList.remove('selected');
                    }
                }
            }
        });
        el.querySelector('li').classList.add('selected');
        el.querySelector('.org span').textContent += ' (' + totalCount + ')';
        orgListDiv.appendChild(el);

        for (let idx in opOrgs) {
            const opOrgBuildings = [];
            const { name } = opOrgs[Number(idx)];
            const opOrg = new OpOrganization({
                ...opOrgs[Number(idx)],
                organizations: (await getOrganizationsByOpOrg(name)).filter(function (org) {
                    return org.status !== ORG_ACTION.HIDDEN;
                }),
            });
            opOrg.organizationCount = opOrg.organizations.length;

            // set organizations for each buildings to list
            for (let idx in opOrg.organizations) {
                const org = opOrg.organizations[Number(idx)];
                // get building
                if (org.building !== null) {
                    const exists = opOrgBuildings.filter(function (building) {
                        return org.status !== ORG_ACTION.HIDDEN &&
                            building.name === org.building;
                    });

                    if (!exists.length) {
                        if (!(org.building instanceof Building)) {
                            const buildingObj = getBuildingByName(org.building);
                            if (buildingObj) {
                                org.building = buildingObj;
                            } else {
                                org.building = new Building({
                                    name: org.building,
                                    address_base: org.address_base,
                                    opOrg: org.op_org,
                                    position: org.position ? org.position : {
                                        x: org.position_x,
                                        y: org.position_y,
                                    },
                                    has3D: org.show_matterport === "1",
                                    matterportUrl: org.matterport_url,
                                    displayOrder: org.display_order,
                                });
                            }
                        }
                        const orgs = await getOrganizationByAddress(org.address_base);
                        org.building.organizationCount = orgs.length;
                        org.building.organizations = orgs;
                        // check orgs has MP
                        const matches = org.building.organizations.filter(function (item) {
                            return item.show_matterport === "1";
                        });
                        if (matches.length > 0) {
                            org.building.has3D = true;
                        }
                        opOrgBuildings.push(org.building);
                    } else {
                        org.building = exists.pop();
                        if (!org.building.opOrg) {
                            org.building.opOrg = org.op_org;
                        }
                    }
                } else if (org.position || (org.position_x && org.position_y)) {
                    const exists = buildings.filter(function (building) {
                        return org.status !== ORG_ACTION.HIDDEN &&
                            building.address_base === org.address_base;
                    });

                    if (!exists.length) {
                        // create new independent building
                        const building = new Building({
                            name: org.name,
                            address_base: org.address_base,
                            opOrg: org.op_org,
                            position: org.position ? org.position : {
                                x: org.position_x,
                                y: org.position_y,
                            },
                            has3D: org.show_matterport === "1",
                            matterportUrl: org.matterport_url,
                            displayOrder: org.display_order,
                        });
                        if (org.type !== null) {
                            building.type = org.type;
                        }
                        if (org.display_option !== null) {
                            building.displayOption = org.display_option;
                        }
                        if (org.display_order !== null) {
                            building.displayOrder = org.display_order;
                        }
                        const orgList = getOrganizationByAddress(building.address_base)
                            .filter(function (item) {
                                return org.status !== ORG_ACTION.HIDDEN &&
                                    item.name !== building.name;
                            });
                        building.organizationCount = orgList.count;
                        building.organizations = orgList;
                        // check orgs has MP
                        const matches = building.organizations.filter(function (org) {
                            return org.show_matterport === "1";
                        });
                        if (matches.length > 0) {
                            building.has3D = true;
                        }
                        org.building = building;
                        opOrgBuildings.push(building);
                        buildings.push(building);
                    } else {
                        org.building = exists.pop();
                        if (!org.building.opOrg) {
                            org.building.opOrg = org.op_org;
                        }
                    }
                }
                const marker = createMarkerWithBuilding(mapLayer, org.building, await onClickMarker(org));
                // create sub organization list
                const subOrgListDiv = marker.querySelector('.sub-org-list');
                // clear list
                clearAllChildNodes(subOrgListDiv);
                // hide list
                if (!subOrgListDiv.classList.contains('hidden')) {
                    subOrgListDiv.classList.add('hidden');
                }

                const markerBuilding = opOrgBuildings.filter(function (item) {
                    return item.name === marker.dataset.name;
                }).pop();
                if (markerBuilding && markerBuilding.organizations) {
                    for (let idx in markerBuilding.organizations) {
                        const org = (markerBuilding.organizations[idx] instanceof Organization) ?
                            building.organizations[idx] :
                            new Organization(markerBuilding.organizations[idx]);
                        if (!(org.building instanceof Building)) {
                            org.building = getBuildingByName(org.building);
                        }
                        if (org.type !== BUILDING_TYPE.BUILDING) {
                            continue;
                        }
                        if (org.status === ORG_ACTION.HIDDEN) {
                            continue;
                        }
                        const itemDiv = org.parseListItemElement();
                        subOrgListDiv.appendChild(itemDiv);
                    }
                    subOrgListDiv.style.bottom = `${subOrgListDiv.offsetHeight + markerOffsetHeight}px`;
                    const markerCount = marker.querySelector('.marker-text .count');
                    if (markerCount) {
                        markerCount.textContent = `+${subOrgListDiv.querySelectorAll('.item:not(.hidden)').length}`;
                    }
                }
            }
            if (!opOrg.hidden) {
                orgListDiv.appendChild(
                    opOrg.parseListItemElement(onClickOpOrgListItem(opOrg))
                );
            }
            // const markers = document.querySelectorAll('#mapLayer .marker');
        }

        // set notice message
        if (notice !== null) {
            const messageWrapper = document.querySelector('.billboard .message .wrapper');
            const containerWidth = messageWrapper.offsetWidth;
            let breakLoop = false;
            for (let i = 1; i <= 10; i += 1) {
                const messages = messageWrapper.querySelectorAll(':nth-child(n)');
                if (messages !== null) {
                    let messagesWidth = 0;
                    messages.forEach(function (message) {
                        messagesWidth += message.offsetWidth;
                        if (messagesWidth >= containerWidth * 2) {
                            breakLoop = true;
                            return;
                        }
                    });
                }
                if (breakLoop) {
                    break;
                }
                const messagePartDiv = document.createElement('div');
                messagePartDiv.classList.add('parts');
                messagePartDiv.innerText = notice;
                messageWrapper.appendChild(messagePartDiv);
            }
            // ref: https://javascript.tutorialink.com/marquee-text-effect-same-scrolling-speed-no-matter-the-length-of-the-text/
            const timePerPixel = 10;
            const messages = messageWrapper.querySelectorAll('.parts');
            messages.forEach(function (message) {
                const messageWidth = Number(getComputedStyle(message).width.replace('px', ''));
                const rightMargin = Number(getComputedStyle(message).marginRight.replace('px', ''));
                const distance = messageWidth + rightMargin;
                const duration = timePerPixel * distance;
                console.debug(`message width: ${messageWidth} right margin: ${rightMargin} distance: ${distance} duration: ${duration}`);
                message.style.animationDuration = duration + 'ms';
            });
        }

        window.dispatchEvent(new CustomEvent('marker-created'));
        initEvent({ organizations, buildings, opOrgs });
    };

    return exports;
})();

document.addEventListener('DOMContentLoaded', async function () {
    const organizations = await window.axios({
        url: '/api/orgs?limit=9999'
    })
        .then(function (response) {
            const { data } = response.data;
            const orgs = [];
            for (let idx in data) {
                const org = data[idx];
                orgs.push(new Organization(org));
            }
            return orgs;
        })
        .catch(function (e) {
            console.error(e);
        });

    const buildings = await window.axios({
        url: '/api/config/building'
    })
        .then(function (response) {
            const { data } = response;
            const buildings = [];
            for (let idx in data) {
                const building = new Building(data[idx]);
                buildings.push(building);
            }
            return buildings;
        })
        .catch(function (e) {
            console.error(e);
        });

    const notice = await window.axios({
        url: '/api/notice'
    })
        .then(function (response) {
            const { message } = response.data;

            return message;
        })

    await window.Fassker.init({ organizations, buildings, notice });
});
