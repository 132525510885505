// import OpOrganization from './opOrganization';
import Organization from './organization';
import Building from './building';
import Company from './company';
// import Marker from './marker';
import constant from './constant';
import util from './util';
// import { setTimeout } from 'core-js';

// const { createMarkerWithBuildings } = Marker;
const { createDiv, clearAllChildNodes, changeImage } = util;
const { BUILDING_TYPE, BUTTON_ICON, BUTTON_INVERT_ICON, ORG_ACTION, markerOffsetHeight } = constant;

function addSpacer(target) {
    // check scroll is need
    const isScroll = target.scrollHeight > target.offsetHeight;
    // get list item counts
    const items = target.querySelectorAll('.item');
    const counts = items.length;
    const lastItem = items[counts - 1];
    // if (isScroll && counts) {
    const spacerDiv = createDiv();
    spacerDiv.classList.add('spacer');
    spacerDiv.style.height = `${target.offsetHeight - lastItem.offsetHeight + 1}px`;
    target.appendChild(spacerDiv);
    // }
}

// hide sub-org items other than op-org
function showOnlySubOrg(opOrg) {
    const subOrgItems = document.querySelectorAll('#mapLayer .marker .sub-org-list .item');
    for (let idx in subOrgItems) {
        const item = subOrgItems[Number(idx)];
        if (!item || !item.dataset.org) {
            continue;
        }
        if (item.dataset.org !== opOrg.name) {
            item.classList.add('hidden');
        } else {
            item.classList.remove('hidden');
        }
    }
}

function updateMarkerCount() {
    const markers = document.querySelectorAll('#mapLayer .marker');
    markers.forEach(function (marker) {
        const markerCount = marker.querySelector('.marker-text .count');
        if (markerCount) {
            const itemCount = marker.querySelectorAll('.sub-org-list .item:not(.hidden)').length;
            if (itemCount > 0) {
                // show marker
                marker.classList.remove('hidden');
                markerCount.textContent = `+${itemCount}`
            } else {
                // hide marker
                marker.classList.add('hidden');
                markerCount.textContent = '';
            }
        }
    })
}

function onClickItem(org) {
    return function (e) {
        e.stopPropagation();
        // remove selected class if exists
        const panelList = document.querySelector('.panel > .list');
        for (let idx in panelList.childNodes) {
            const item = panelList.childNodes.item(Number(idx));
            if (item && item.classList.contains('selected')) {
                item.classList.remove('selected');
                // replace image(s)
                changeImage(item.querySelector('.links .homepage img'), BUTTON_ICON.homepage);
                changeImage(item.querySelector('.links .companies img'), BUTTON_ICON.list);
            }
        }
        // select building which organization is in
        if (!this.classList.contains('selected')) {
            this.classList.add('selected');
            // replace image(s)
            changeImage(this.querySelector('.links .homepage img'), BUTTON_INVERT_ICON.homepage);
            changeImage(this.querySelector('.links .companies img'), BUTTON_INVERT_ICON.list);
        } else {
            this.classList.remove('selected');
        }
        window.dispatchEvent(new CustomEvent('panel-org-selected', {
            detail: {
                name: org.name,
                building: org.building,
            }
        }));
        return false;
    };
}

export function initEvent(options) {
    const { organizations, buildings, opOrgs } = options;
    // create events
    window.addEventListener('panel-open', function () {
        // shift map
        const map = document.getElementById('map');
        if (!map.classList.contains('shifted')) {
            map.classList.add('shifted');
        }

        const target = document.querySelector('.panel .list');
        if (target) {
            // add spacer
            addSpacer(target);
        }
    });

    window.addEventListener('panel-closed', function () {
        // unshift map
        const map = document.getElementById('map');
        if (map.classList.contains('shifted')) {
            map.classList.remove('shifted');
        }
    });

    window.addEventListener('sub-org-selected', async function (ev) {
        const detail = ev.detail;
        const { org } = detail;

        if (!org) {
            console.error('org not found:', ev);
            return;
        }

        // open panel if not
        // const opOrg = new OpOrganization(opOrgs.filter(function (item) {
        //     return item.name === org.op_org;
        // }).pop());
        // opOrg.organizations = await opOrg.getOrganizations();
        // opOrg.organizationCount = opOrg.organizations.length;
        const menuItems = document.querySelectorAll('.menu.left .org-list li');
        for (let idx in menuItems) {
            const item = menuItems[Number(idx)];
            if (item && item.getAttribute('data-org') === org.op_org) {
                item.dispatchEvent(new MouseEvent('click'));
                break;
            }
        }

        // remove selected class if exists
        const items = document.querySelectorAll('.panel .list .item');
        for (let idx in items) {
            const item = items[Number(idx)];
            if (item && item.classList.contains('selected')) {
                item.classList.remove('selected');
                // replace image(s)
                changeImage(item.querySelector('.links .homepage img'), BUTTON_ICON.homepage);
                changeImage(item.querySelector('.links .companies img'), BUTTON_ICON.list);
            }
        }

        // find organization item element in panel list
        let orgItem;
        for (let idx in items) {
            const item = items[Number(idx)];
            if (item) {
                // get name
                const itemName = item.querySelector('.details > .name').textContent;
                if (org.name === itemName) {
                    orgItem = item;
                    break;
                }
            }
        }
        // scroll to organization in panel list
        if (orgItem) {
            const panel = document.querySelector('.panel');
            const panelList = panel.querySelector('.list');
            panelList.scrollTo({
                top: orgItem.offsetTop,
                behavior: 'smooth',
            });
            orgItem.classList.add('selected');
            // replace image(s)
            changeImage(orgItem.querySelector('.links .homepage img'), BUTTON_INVERT_ICON.homepage);
            changeImage(orgItem.querySelector('.links .companies img'), BUTTON_INVERT_ICON.list);
        }
    });

    window.addEventListener('panel-org-selected', function (ev) {
        const detail = ev.detail;

        // close company list
        const companyList = document.querySelector('.company-list');
        if (!companyList.classList.contains('hidden')) {
            companyList.classList.add('hidden');
        }

        // find building
        const markers = document.querySelectorAll('#mapLayer > .marker');
        // close other marker if any
        for (let idx in markers) {
            const marker = markers[Number(idx)];
            if (!marker) {
                continue;
            }
            if (marker.classList.contains('selected')) {
                marker.dispatchEvent(new MouseEvent('click', {
                    bubbles: false,
                }));
            }
        }

        let target;
        for (let idx in markers) {
            const marker = markers[Number(idx)];
            if (!marker) {
                return;
            }
            const buildingName = marker.getAttribute('data-name');
            if (detail.building && buildingName === detail.building.name) {
                target = marker;
                break;
            } else if (buildingName === detail.name) {
                // if building is not assigned, search with the name
                target = marker;
                break;
            }
        }
        if (target) {
            // close other sub org. list if opened
            const subOrgLists = document.querySelectorAll(`#mapLayer > .marker:not([data-org="${target.dataset.org}"]) > .sub-org-list`);
            for (let idx in subOrgLists) {
                const subOrgList = subOrgLists[Number(idx)];
                if (subOrgList) {
                    if (!subOrgList.classList.contains('hidden')) {
                        // trigger click event to close
                        subOrgList.parentElement.dispatchEvent(new MouseEvent('click', {
                            bubbles: false,
                        }));
                    } else if (subOrgList.parentElement.classList.contains('selected')) {
                        // trigger click event to deselect
                        subOrgList.parentElement.dispatchEvent(new MouseEvent('click', {
                            bubbles: false,
                        }));
                    }
                }
            }
            // trigger click event to open sub org. list in target
            const subOrgList = target.querySelector('.sub-org-list');
            if (subOrgList.classList.contains('hidden')) {
                target.dispatchEvent(new MouseEvent('click'));
            }
            // select sub org. in list
            const subOrgListItems = subOrgList.querySelectorAll('.item');
            for (let idx in subOrgListItems) {
                const item = subOrgListItems[Number(idx)];
                if (item) {
                    const itemName = item.getAttribute('data-name');
                    if (itemName === detail.name) {
                        // trigger click event
                        item.dispatchEvent(new MouseEvent('click', {
                            bubbles: false,
                        }));
                        // scroll to sub org. item
                        subOrgList.scrollTo({
                            top: item.offsetTop,
                            behavior: 'smooth',
                        });
                        break;
                    }
                }
            }
            subOrgList.style.bottom = `${subOrgList.offsetHeight + markerOffsetHeight}px`;
            updateMarkerCount();
        } else {
            console.warn('target not found:', { detail });
        }
    });

    window.addEventListener('menu-select', async function (ev) {
        const detail = ev.detail;
        const opOrg = detail.opOrg;
        const orgs = opOrg.organizations;
        const panelDiv = document.querySelector('.container > .panel');
        // check panel open
        if (!panelDiv.classList.contains('hidden')) {
            // check same op-org we tried to open
            const opOrgName = panelDiv.querySelector('.top .name');
            if (opOrgName && opOrg.name === opOrgName.textContent) {
                // skip to create
                return;
            }
        }
        // clear panel child node
        clearAllChildNodes(panelDiv);

        // create top panel
        const topDiv = createDiv();
        topDiv.classList.add('top');
        if (opOrg.thumbnailUrl !== null) {
            topDiv.style.backgroundImage = `url('${opOrg.thumbnailUrl}')`;
        }

        // add wrapper
        const wrapperDiv = createDiv();
        wrapperDiv.classList.add('wrapper');

        const orgInfoDiv = createDiv();
        orgInfoDiv.classList.add('org-info');

        // add details
        const detailDiv = opOrg.parseDetailsElement();
        orgInfoDiv.appendChild(detailDiv);

        wrapperDiv.appendChild(orgInfoDiv);
        topDiv.appendChild(wrapperDiv);

        // create org list
        const listDiv = createDiv();
        listDiv.classList.add('list');

        for (let idx in orgs) {
            const org = orgs[Number(idx)];
            // if (org.building instanceof Promise) {
            //     org.building = await org.building;
            // }
            if (org.status === ORG_ACTION.HIDDEN) {
                // skip
                continue;
            }
            const itemDiv = createDiv();
            itemDiv.classList.add('item');
            itemDiv.addEventListener('click', onClickItem(org));

            const detailsDiv = org.parseDetailsElement();

            itemDiv.appendChild(detailsDiv);
            listDiv.appendChild(itemDiv);
        }

        panelDiv.appendChild(topDiv);
        panelDiv.appendChild(listDiv);

        if (panelDiv.classList.contains('hidden')) {
            panelDiv.classList.remove('hidden');
        }

        // const buildings = await opOrg.getBuildingList();
        const opOrgBuildings = buildings.filter(function (building) {
            return building.opOrg === opOrg.name ||
                opOrg.organizations.filter(function (org) {
                    return org.name === building.name;
                }).length ||
                building.organizations.filter(function (org) {
                    return org.op_org === opOrg.name;
                }).length;
        });

        // hide building markers of other building
        const target = document.getElementById('mapLayer');
        const markers = target.querySelectorAll('.marker');
        for (let idx in markers) {
            const item = markers[Number(idx)];
            if (item) {
                if (item.classList.contains(BUILDING_TYPE.UNIV) ||
                    item.classList.contains(BUILDING_TYPE.PROVINCE) ||
                    item.classList.contains(BUILDING_TYPE.HOSPITAL)) {
                    // skip other than building
                    continue;
                }
                const matched = opOrgBuildings.filter(function (building) {
                    return building.name === item.dataset.name;
                }).length;
                if (matched) {
                    if (item.classList.contains('hidden')) {
                        item.classList.remove('hidden');
                    }
                } else {
                    item.classList.add('hidden');
                    if (item.classList.contains('selected')) {
                        // remove selected
                        item.classList.remove('selected');
                        // hide sub-org list
                        item.querySelector('.sub-org-list').classList.add('hidden');
                    }
                }
            }
        }

        // show only sub-orgs that belongs to op-org
        showOnlySubOrg(opOrg);

        // close any sub-org. list if opened except trying to open
        const subOrgLists = document.querySelectorAll('.sub-org-list:not(.hidden)');
        for (let idx in subOrgLists) {
            const item = subOrgLists[Number(idx)];
            if (item) {
                const matched = opOrgBuildings.filter(function (building) {
                    return building.name === item.parentElement.dataset.name;
                });
                if (!matched.length) {
                    item.classList.add('hidden');
                } else {
                    item.style.bottom = `${item.offsetHeight + markerOffsetHeight}px`;
                }
            }
        }

        updateMarkerCount();

        // dispatch panel open event
        window.dispatchEvent(new CustomEvent('panel-open'));
    });

    window.addEventListener('showcase-open', function (ev) {
        const detail = ev.detail;
        // open showcase URL into iframe
        const iframe = document.getElementById('showcase');
        iframe.src = detail.src;

        const showcaseDiv = document.querySelector('.container > .showcase');
        showcaseDiv.classList.remove('hidden');
        const showcaseCloseDiv = document.getElementById('showcase_close');
        showcaseCloseDiv.classList.remove('hidden');
    });

    window.addEventListener('showcase-close', function () {
        // open blank URL into iframe
        const iframe = document.getElementById('showcase');
        iframe.src = 'about:blank';

        const showcaseDiv = document.querySelector('.container > .showcase');
        showcaseDiv.classList.add('hidden');
        const showcaseCloseDiv = document.getElementById('showcase_close');
        showcaseCloseDiv.classList.add('hidden');
    });

    window.addEventListener('company-list-open', async function (ev) {
        const { org } = ev.detail;
        const { name } = org;

        // get company list by org name
        await axios.get(`/api/org/${name}/companies`)
            .then(function (response) {
                const companies = response.data;
                if (!companies.length) {
                    return;
                }
                const companyList = document.querySelector('.company-list');
                if (companyList.classList.contains('hidden')) {
                    companyList.classList.remove('hidden');
                }

                // clear all list
                clearAllChildNodes(companyList);

                for (let idx in companies) {
                    const company = new Company(companies[Number(idx)]);
                    if (company.status === 'show') {
                        companyList.appendChild(company.parseListItemElement());
                    }
                }

                if (companyList.childElementCount < 1) {
                    // hide list
                    companyList.classList.add('hidden');
                }
            })
            .catch(function (e) {
                console.error(e);
            });
    });

    window.addEventListener('company-list-close', function () {
        const companyList = document.querySelector('.company-list');
        if (!companyList.classList.contains('hidden')) {
            companyList.classList.add('hidden');
        }

        // clear all list
        clearAllChildNodes(companyList);
    });

    window.addEventListener('marker-created', function () {
        const target = document.querySelector('.panel .list');
        if (target) {
            // add spacer
            addSpacer(target);
        }
    });

}

// module.exports = { initEvent };
