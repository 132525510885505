import constant from './constant';
import util from './util';

const { changeImage } = util;
const { BUTTON_ICON, BUILDING_TYPE, markerOffsetHeight } = constant;

export async function onClickMarker(org) {
    return function (e) {
        const target = e.target;
        let marker;
        if (target.classList.contains('marker')) {
            marker = target;
        } else if (target.parentNode.classList.contains('marker')) {
            marker = target.parentNode;
        } else {
            // ignore target if it is not from marker
            return;
        }

        // should not selected other than building
        if (marker.classList.contains(BUILDING_TYPE.UNIV) ||
            marker.classList.contains(BUILDING_TYPE.PROVINCE) ||
            marker.classList.contains(BUILDING_TYPE.HOSPITAL)
        ) {
            return;
        }

        if (marker.classList.contains('selected')) {
            // hide sub-org list if open
            const subOrgListDiv = marker.querySelector('.sub-org-list');
            if (!subOrgListDiv.classList.contains('hidden')) {
                subOrgListDiv.classList.add('hidden');
            }
            // unselect marker
            marker.classList.remove('selected');

            // unselect sub-org item from panel list
            const selectedSubOrgItem = document.querySelector('.panel .list .item.selected');
            if (selectedSubOrgItem) {
                selectedSubOrgItem.classList.remove('selected');
                // replace image(s)
                changeImage(selectedSubOrgItem.querySelector('.links .homepage img'), BUTTON_ICON.homepage);
                changeImage(selectedSubOrgItem.querySelector('.links .companies img'), BUTTON_ICON.list);
            }

            return;
        }
        // unselect other marker
        const selected = document.querySelector('#mapLayer > .marker.selected');
        if (selected && selected !== marker) {
            selected.dispatchEvent(new MouseEvent('click'));
        }


        const subOrgListDiv = marker.querySelector('.sub-org-list');
        // unselect any sub-org. item
        const subOrgItemSelected = subOrgListDiv.querySelectorAll('.item.selected');
        if (subOrgItemSelected.length) {
            for (let idx in subOrgItemSelected) {
                const item = subOrgItemSelected[Number(idx)];
                if (item) {
                    item.classList.remove('selected');
                }
            }
        }
        // hide list
        if (!subOrgListDiv.classList.contains('hidden')) {
            subOrgListDiv.classList.add('hidden');
            if (marker.classList.contains('selected')) {
                marker.classList.remove('selected');
            }
        } else if (subOrgListDiv.childNodes.length > 0) {
            subOrgListDiv.classList.remove('hidden');
        }
        const isAllSubOrgHidden = subOrgListDiv.querySelectorAll('.item.hidden').length ===
            subOrgListDiv.querySelectorAll('.item').length;
        if (isAllSubOrgHidden) {
            // hide sub-org list
            subOrgListDiv.classList.add('hidden');
        }
        // find and select org by name
        if (!subOrgListDiv.querySelectorAll('.item').length || isAllSubOrgHidden) {
            if (org) {
                window.dispatchEvent(new CustomEvent('sub-org-selected', {
                    detail: {
                        org,
                    }
                }));
            }
        }

        if (!marker.classList.contains('selected')) {
            marker.classList.add('selected');
        }
        subOrgListDiv.style.bottom = `${subOrgListDiv.offsetHeight + markerOffsetHeight}px`;
        const markerCount = marker.querySelector('.marker-count');
        if (markerCount) {
            markerCount.textContent = `+${subOrgListDiv.querySelectorAll('.item:not(.hidden)').length}`;
        }
    };
}
