import util from './util';
import constant from './constant';

const { createDiv } = util;
const { BUTTON_ICON } = constant;

export default class Company {
    constructor(properties) {
        const defaultProperties = {
            homepage: null,
            status: 'show',
            show_homepage: '0',
            logo_url: null,
        };
        this.properties = Object.assign({}, defaultProperties, properties);

        return {
            ...this.properties,
            parseListItemElement: this.parseListItemElement,
        };
    }

    parseListItemElement() {
        const company = this;
        if (this.status === 'hidden') {
            return createDiv();
        }
        const detailsDiv = createDiv();
        detailsDiv.classList.add('details');

        // logo
        const logoDiv = createDiv();
        logoDiv.classList.add('logo');
        if (company.logo_url) {
            const img = document.createElement('img');
            img.src = company.logo_url;
            logoDiv.appendChild(img);
        }
        detailsDiv.appendChild(logoDiv);

        // name
        const nameDiv = createDiv();
        nameDiv.classList.add('name');
        nameDiv.textContent = company.name;
        detailsDiv.appendChild(nameDiv);

        // description
        const descriptionDiv = createDiv();
        descriptionDiv.classList.add('description');
        descriptionDiv.innerHTML = company.description;
        detailsDiv.appendChild(descriptionDiv);

        // homepage
        if (company.show_homepage === '1' && company.homepage !== null) {
            const aTag = document.createElement('a');
            aTag.href = company.homepage;
            aTag.target = '_blank';
            aTag.classList.add('link', 'homepage');
            // aTag.textContent = '홈페이지';

            const span = document.createElement('span');
            span.textContent = '홈페이지';

            const button = document.createElement('button');

            const img = new Image();
            img.src = BUTTON_ICON.homepage;

            button.appendChild(span);
            button.appendChild(img);
            aTag.appendChild(button);

            detailsDiv.appendChild(aTag);
        }

        return detailsDiv;
    }
}
