const characterLimit = 18;
const characterLimitMultiple = 16;

const markerOffsetHeight = 29.1;

const OPORG_ALL = '전체';

const opOrgs = [{
    name: '성남산업진흥원',
    description: "성남산업진흥원에서 운영하고 있는<br/>중소 · 벤처기업 지원기관",
    thumbnailUrl: 'images/thumbnail/snip.png',
}, {
    name: '경기도',
    description: "경기도에서 운영하고 있는<br/>중소 · 벤처기업 지원기관",
    thumbnailUrl: 'images/thumbnail/gyunggi.png',
}, {
    name: '성남시',
    description: "성남시에서 운영하고 있는<br/>중소 · 벤처기업 지원기관",
    thumbnailUrl: "images/thumbnail/seongnam.png"
}, {
    name: '정부',
    description: "정부에서 운영하고 있는<br/>중소 · 벤처기업 지원기관",
    thumbnailUrl: 'images/thumbnail/gov.png',
}, {
    name: '대학',
    description: '대학교에서 운영하고 있는<br/>중소 · 벤처기업 지원기관',
    thumbnailUrl: 'images/thumbnail/univ.png',
}, {
    name: '민간',
    description: '민간전문기업에서 운영하고<br/>있는 중소 · 벤처기업 지원기관',
    thumbnailUrl: 'images/thumbnail/private.png',
}];

const BUILDING_TYPE = {
    BUILDING: 'building',
    UNIV: 'univ', // 대학
    PROVINCE: 'province', // 지자체(=성남시)
    HOSPITAL: 'hospital', // 병원
};

const BUILDING_ICON = {
    [BUILDING_TYPE.UNIV]: 'images/icon/school.png',
    [BUILDING_TYPE.PROVINCE]: 'images/icon/cityhall.png',
    [BUILDING_TYPE.HOSPITAL]: 'images/icon/hospital.png',
};

const BUTTON_ICON = {
    list: 'images/icon/list.png',
    homepage: 'images/icon/right_arrow.png',
    showcase: 'images/icon/3d.png',
};

const BUTTON_INVERT_ICON = {
    list: 'images/icon/list_w.png',
    homepage: 'images/icon/right_arrow_w.png',
};

const ORG_ACTION = {
    HIDDEN: 'hidden', // 목록, 지도에서 숨김
    SHOW_INFO: 'show_info', // 지원기관 목록에 표시
    DISPLAY_ONLY: 'display_only', // 지도에 표시만
};

export default {
    characterLimit,
    characterLimitMultiple,
    opOrgs,
    BUILDING_TYPE,
    BUILDING_ICON,
    BUTTON_ICON,
    BUTTON_INVERT_ICON,
    ORG_ACTION,
    OPORG_ALL,
    markerOffsetHeight,
};
