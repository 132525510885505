import Organization from './organization';

export default class OpOrganization extends Organization {
    constructor(properties) {
        const parentProperties = super(properties);
        const defaultProperties = {
            isOpOrg: true,
            hidden: false,
            organizations: [],
            organizationCount: 0,
        };
        this.properties = Object.assign(parentProperties || {}, defaultProperties, properties);
        if (!this.properties.isOpOrg) {
            this.properties.isOpOrg = true;
        }

        return {
            ...this.properties,
            parseListItemElement: this.parseListItemElement,
            getBuildingList: this.getBuildingList,
            getOrganizationsByBuilding: this.getOrganizationsByBuilding,
            getOrganizationsByAddress: this.getOrganizationsByAddress,
        };
    }

    parseListItemElement(cb) {
        const opOrg = this;
        const ul = document.createElement('ul');
        const li = document.createElement('li');
        li.classList.add('org');
        li.setAttribute('data-org', opOrg.name);
        li.addEventListener('click', function (ev) {
            ev.stopPropagation();
            if (cb !== undefined && cb instanceof Function) {
                cb.apply(this, [ev]);
            }
            return false;
        });

        const span = document.createElement('span');
        span.textContent = opOrg.name;

        li.appendChild(span);
        ul.appendChild(li);

        return ul;
    }

    parseDetailsElement() {
        const detailsDiv = super.parseDetailsElement();

        const companyLink = detailsDiv.querySelector('.link.companies');
        if (companyLink) {
            detailsDiv.removeChild(companyLink);
        }

        const showcaseLink = detailsDiv.querySelector('.link.showcase');
        if (showcaseLink) {
            detailsDiv.removeChild(showcaseLink);
        }

        return detailsDiv;
    }

    async getBuildingList() {
        const opOrg = this;
        if (!opOrg.organizations) {
            return;
        }
        const orgs = opOrg.organizations;
        const buildings = [];
        for (let idx in orgs) {
            const org = (orgs[idx] instanceof Organization) ? orgs[idx] : new Organization(orgs[idx]);
            if (org.building !== null) {
                if (org.building instanceof Promise) {
                    org.building = await org.building;
                }
                const exists = buildings.filter(function (building) {
                    return building && building.address_base === org.address_base;
                });

                if (!exists.length) {
                    const orgs = this.getOrganizationsByAddress(org.address_base);
                    if (org.building instanceof Promise) {
                        org.building = await org.building;
                    }
                    org.building.organizationCount = orgs.length;
                    org.building.organizations = orgs;
                    buildings.push(org.building);
                }
            }
        }

        return buildings;
    }

    getOrganizationsByAddress(address) {
        const orgs = this.organizations.filter(function (org) {
            return org.address_base.indexOf(address) !== -1;
        });

        return orgs;
    }

    getOrganizationsByBuilding(name) {
        const orgs = this.organizations.filter(function (org) {
            return ((!org.building && org.name === name) || org.building.name === name) &&
                org.action === 'show_info';
        });

        return orgs;
    }
}
