import util from './util';
import constant from './constant';
const { createDiv, createSpan } = util;
const { ORG_ACTION, BUILDING_TYPE, BUTTON_ICON } = constant;

export default class Organization {
    constructor(properties) {
        const _defaultProperties = {
            type: BUILDING_TYPE.BUILDING, // see BUILDING_TYPE constant
            status: ORG_ACTION.SHOW_INFO, // or 'hidden'
            display_order: null,
            display_option: null,
            address_detail: null,
            show_companies: '0',
            show_homepage: '0',
            homepage: null,
            show_matterport: '0',
            matterport_url: null,
            building: null,
            position: null,
            isOpOrg: false,
            description: null,
            companies_count: 0,
        };
        this.properties = Object.assign({}, _defaultProperties, properties);


        if (this.properties.position === null && this.properties.position_x && this.properties.position_y) {
            this.properties.position = {
                x: this.properties.position_x,
                y: this.properties.position_y,
            };
            delete this.properties.position_x;
            delete this.properties.position_y;
        }

        return {
            ...this.properties,

            parseDetailsElement: this.parseDetailsElement,
            parseListItemElement: this.parseListItemElement,
        };
    }

    parseListItemElement() {
        const org = this;
        const itemDiv = createDiv();
        itemDiv.classList.add('item');
        itemDiv.dataset.name = org.name;
        itemDiv.dataset.org = org.op_org;
        itemDiv.addEventListener('click', function (e) {
            e.stopPropagation();
            if (org.action === ORG_ACTION.HIDDEN) {
                return;
            }
            // deselect all other items
            for (let idx in this.parentNode.childNodes) {
                const item = this.parentNode.childNodes.item(Number(idx));
                if (item === this) {
                    // skip
                    continue;
                }
                if (item && item.classList.contains('selected')) {
                    item.classList.remove('selected');
                }
            }
            if (!this.classList.contains('selected')) {
                this.classList.add('selected');
                window.dispatchEvent(new CustomEvent('sub-org-selected', {
                    detail: {
                        org,
                    }
                }));
            }
            return false;
        });
        const nameDiv = createDiv();
        nameDiv.classList.add('name');
        let name = org.name;
        nameDiv.textContent = name;

        itemDiv.appendChild(nameDiv);

        // add MP link
        if (org.show_matterport === '1' && org.matterport_url !== null) {
            const matterportDiv = createDiv();
            matterportDiv.classList.add('mp');

            const matterportLink = document.createElement('a');
            matterportLink.classList.add('link', 'showcase');
            matterportLink.setAttribute('href', '#');
            matterportLink.addEventListener('click', function (e) {
                e.stopPropagation();
                window.dispatchEvent(new CustomEvent('showcase-open', {
                    detail: {
                        src: org.matterport_url,
                    },
                }));
                return false;
            });

            const button = document.createElement('button');
            matterportLink.appendChild(button);

            matterportDiv.appendChild(matterportLink);
            itemDiv.appendChild(matterportDiv);
            itemDiv.classList.add('mp');
        }

        return itemDiv;
    }

    parseDetailsElement() {
        const org = this;
        const detailsDiv = createDiv();
        detailsDiv.classList.add('details');

        const nameSpan = createDiv();
        nameSpan.classList.add('name');
        nameSpan.textContent = org.name;
        detailsDiv.appendChild(nameSpan);

        if (org.isOpOrg) {
            const descriptionSpan = createDiv();
            descriptionSpan.classList.add('description');
            descriptionSpan.innerHTML = org.description;
            detailsDiv.appendChild(descriptionSpan);
        } else {
            const addressSpan = createDiv();
            addressSpan.classList.add('address');
            addressSpan.textContent = org.address_base;
            if (org.address_detail !== null) {
                addressSpan.innerHTML += `<br>${org.address_detail}`;
            }
            detailsDiv.appendChild(addressSpan);

            if (org.telephone) {
                const telephoneSpan = createDiv();
                telephoneSpan.classList.add('tel');
                telephoneSpan.textContent = org.telephone;
                detailsDiv.appendChild(telephoneSpan);
            }

            const linksDiv = createDiv();
            linksDiv.classList.add('links');

            if (org.show_companies === '1' && org.companies_count > 0) {
                const companyLink = document.createElement('a');
                companyLink.classList.add('link', 'companies');
                companyLink.setAttribute('href', '#');

                const companyLinkButton = document.createElement('button');

                const companyLinkSpan = createSpan();
                companyLinkSpan.textContent = '업체소개';
                companyLinkButton.appendChild(companyLinkSpan);

                const companyLinkLogoImg = new Image();
                companyLinkLogoImg.src = BUTTON_ICON.list;
                companyLinkButton.appendChild(companyLinkLogoImg);

                companyLink.appendChild(companyLinkButton);

                companyLink.addEventListener('click', function (e) {
                    e.stopPropagation();
                    window.dispatchEvent(new CustomEvent('company-list-open', {
                        detail: {
                            org,
                        }
                    }));
                });
                linksDiv.appendChild(companyLink);
            }

            if (org.show_homepage === '1' && org.homepage !== null) {
                const homepageLink = document.createElement('a');
                homepageLink.classList.add('link', 'homepage');
                homepageLink.setAttribute('href', org.homepage);
                homepageLink.setAttribute('target', '_blank');

                const homepageButton = document.createElement('button');

                const span = createSpan();
                span.textContent = '홈페이지';
                homepageButton.appendChild(span);

                const img = new Image();
                img.src = BUTTON_ICON.homepage;
                homepageButton.appendChild(img);

                homepageLink.appendChild(homepageButton);

                linksDiv.appendChild(homepageLink);
            }

            if (org.show_matterport === '1' && org.matterport_url !== null) {
                const matterportLink = document.createElement('a');
                matterportLink.classList.add('link', 'showcase');
                matterportLink.setAttribute('href', '#');
                matterportLink.addEventListener('click', function (e) {
                    e.stopPropagation();
                    window.dispatchEvent(new CustomEvent('showcase-open', {
                        detail: {
                            src: org.matterport_url,
                        },
                    }));
                    return false;
                });
                const button = document.createElement('button');

                const span = createSpan();
                span.textContent = '둘러보기';
                button.appendChild(span);

                const img = new Image();
                img.src = BUTTON_ICON.showcase;
                button.appendChild(img);

                matterportLink.appendChild(button);

                linksDiv.appendChild(matterportLink);
            }

            if (linksDiv.childNodes.length) {
                detailsDiv.appendChild(linksDiv);
            }
        }

        return detailsDiv;
    }
}
